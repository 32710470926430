@import 'Colours';

// Font Sizes
$font-sizes: 10, 12, 13, 14, 15, 17, 20, 22, 24, 28, 30, 80;
@each $size in $font-sizes {
  .font-#{$size} {
    font-size: #{$size}px;
  }
}

.font-bold {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 700;
}

.font-light {
  font-family: 'Roboto', 'sans-serif';
  font-weight: 300;
}

.font-regular {
  font-family: 'Roboto', 'sans-serif';
  font-weight: normal;
}

.font-light-grey { color: $light-grey }
.font-grey { color: $grey }
.font-dark-grey { color: $dark-grey }
.font-white { color: white }
.font-light-pink { color: $light-pink }
.font-invalid-red { color: $invalid-red }
.font-primary { color: $brand-primary }
