@import 'Colours';
@import 'Mixins';

input.form-control, textarea.form-control, .input-group-prepend { @include common-input-styles() }

:-ms-input-placeholder { @include common-placeholder-styles }
::-ms-input-placeholder { @include common-placeholder-styles }
::placeholder { @include common-placeholder-styles }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {-moz-appearance: textfield}
