@import 'Colours';
@import 'Mixins';

$box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16), 0 0 6px 0 rgba(0, 0, 0, 0.23);
$box-shadow-hover: 0 0 10px 0 rgba(0, 0, 0, 0.16), 0 0 10px 0 rgba(0, 0, 0, 0.23);
$box-shadow-heavy: 0 14px 28px 0 rgba(0, 0, 0, 0.25), 0 10px 10px 0 rgba(0, 0, 0, 0.26);

.border-grey { border: 1px solid $grey }
.border-light-grey { border: 1px solid $light-grey }
.border-bottom-light-grey { border-bottom: 1px solid $light-grey }

.box-shadow { box-shadow: $box-shadow }
.box-shadow-heavy { box-shadow: $box-shadow-heavy }
.box-shadow-0 { box-shadow: none }
.box-shadow-hover {
  transition: box-shadow .2s;
  &:hover { box-shadow: $box-shadow-hover }
}

.cursor-pointer { cursor: pointer }
.cursor-default { cursor: default }
.cursor-not-allowed { cursor: not-allowed }

.bg-brand-primary { background-color: $brand-primary }
.bg-brand-secondary { background-color: $brand-secondary }
.bg-brand-yellow { background-color: $brand-yellow }
.bg-dark-red { background-color: $dark-red }
.bg-light-grey { background-color: $light-grey }
.bg-lightest-grey { background-color: $lightest-grey }
.bg-medium-grey { background-color: $medium-grey }

.hover-underline { &:hover { text-decoration: underline } }

.isolate { isolation: isolate }

.no-hover { pointer-events: none }

.object-fit-cover { object-fit: cover }

.w-60 { width: 60% }
.w-80 { width: 80% }

.vh-md-20 {
  @include screen-md {
    height: 20vh !important;
  }
}

.vh-md-80 {
  @include screen-md {
    height: 80vh !important;
  }
}

.w-lg-100 {
  @include screen-lg {
    width: 100% !important;
  }
}

.w-md-80 {
  @include screen-md {
    width: 80% !important;
  }
}

// Positioning
$offsets: 4, 30;
@each $offset in $offsets {
  .top-#{$offset} {
    top: #{$offset}px;
  }
}
