$brand-primary: #d0103a;
$brand-secondary: #3f544f;
$brand-yellow: #d9c91a;
$dark-red: #651c32;
$dark-grey: #949494;
$grey: #9e9e9e;
$invalid-red: #dc3545;
$light-grey: #e0e0e0;
$lighter-grey: #f4f7f8;
$lightest-grey: #dcdcdc;
$light-pink: #fabbcb;
$medium-grey: #eeeeee;
$pink: #e8879c;
