@import 'Colours';

$border-radius: 0px;
$btn-padding: .4rem .6rem;
$btn-box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.3);

.btn {
  &:focus, &:active { box-shadow: none !important }
}

@mixin button-common ($bg, $c: white) {
  @extend .font-regular;
  background-color: $bg !important;
  color: $c;
  border-color: $bg !important;
  text-transform: capitalize;
  border-radius: $border-radius;
  padding: $btn-padding;
  transition: all .2s;

  &:disabled {
    background-color: $light-grey !important;
    border: 1px solid $light-grey !important;
    @extend .cursor-not-allowed;
  }

  &:active,
  &:hover:not([disabled]) {
    background-color: $c !important;
    color: $bg !important;
    box-shadow: none;
  }
}

@mixin button-outline-common($c: black) {
  @include button-common(transparent, $c);
  border-color: $c !important;
  box-shadow: none;

  &:hover:not([disabled]) {
    background-color: $c !important;
    color: white !important;
  }

  &:active {
    background-color: transparent !important;
    color: $c !important;
  }

  &:disabled { color: $c }
}

// solid buttons
.btn-primary { @include button-common($brand-primary, white) }
.btn-secondary { @include button-common($brand-secondary, white) }
.btn-yellow { @include button-common($brand-yellow, white) }
.btn-white-primary { @include button-common(white, $brand-primary) }
.btn-white-secondary { @include button-common(white, $brand-secondary) }
.btn-black { @include button-common(black, white) }
.btn-neutral { @include button-common($light-grey, $grey) }

// outline buttons
.btn-outline {
  @include button-outline-common();
  border-color: $light-grey !important;
}
.btn-outline-primary { @include button-outline-common($brand-primary) }
.btn-outline-primary-white {
  @include button-outline-common($brand-primary);
  border-color: $light-grey !important;
}

.btn-x-sm {
  font-size: 12px;
}