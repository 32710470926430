@import 'src/stylesheets/Colours';

$selector-size: 17px;

@mixin range-selector-styles {
  width: $selector-size;
  height: $selector-size;
  cursor: pointer;
  background: $brand-primary;
  box-shadow: none;
}

.slider-container {
  width: 100%;
  .slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    border-radius: 12px;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    @include range-selector-styles;
  }

  .slider::-moz-range-thumb {
    @include range-selector-styles;
  }

  /** FF*/
  .slider::-moz-range-progress {
    background-color: rgba($brand-primary, .5);
    height: 8px;
    border-radius: 12px;
  }
  .slider::-moz-range-track {
    background-color: rgba($light-grey, .5);
    height: 8px;
    border-radius: 12px;
  }
  /* IE*/
  .slider::-ms-fill-lower { background-color: $brand-primary }
  .slider::-ms-fill-upper { background-color: $light-grey }
}
