@import 'src/stylesheets/Colours';

$hamburger-svg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgb%28255, 255, 255' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");

.top-nav-container {
  padding: 0;
  a {
    &:hover {text-decoration: none !important}
    .dropdown-item {
      border-radius: 0;
      transition: background-color .3s, color .3s;
      &:hover,
      &:active,
      &.active {
        background-color: $brand-primary;
        color: white !important;
      }
    }
  }

  .nav-wrapper {
    padding-right: 0;
    padding-left: 0;
    .navbar-toggler {
      padding: 5px;
      border-radius: 0;
      background-color: $brand-primary;
      &:focus-within,
      &:focus-visible,
      &:focus {
        border-color: transparent;
        color: transparent;
        outline: 0;
      }
      span { background-image: $hamburger-svg }
    }
  }
}

.tab-nav-container {
  border: 0;

  .nav-item {
    .nav-link {
      background-color: transparent;
      color: black;
      padding-right: 0;
      border: 0;
      border-bottom-color: transparent;
      border-bottom-width: 2px;
      border-bottom-style: solid;
      transition: .2s all;

      &:hover,
      &.active {
        border-color: $brand-primary;
        box-shadow: none;
        color: black;
      }
    }
  }
}

.tab-nav-container-icon {
  margin-bottom: 10px;

  .tab-content {
    padding-top: 35px;
  }

  .tabs-title {
    padding-top: 7px;
  }

  .nav-item {
    .nav-link {
      background-color: transparent;
      color: black;
      border: 0;
      transition: .2s all;
      padding-top: 0;
      padding-bottom: 2px;
    }
  }
}

.side-nav-container {
  min-width: 210px;
  height: fit-content;

  @include screen-lg { width: 100% }
  ul {
    margin-bottom: 0;
    list-style-type: none;
    padding-inline-start: 0;
    a.nav-link {
      color: black !important;
      border-right: 4px solid transparent;
      transition: border-right-color .2s;
      &.active,
      &:hover {
        text-decoration: none !important;
        border-right-color: $brand-primary;
      }
    }
  }
}
