.progress-bar {
  background-color: white;

  div[role='progressbar'] {
    height: 4px;
    transition: width 2s;
  }
}

.brand-primary {
  .progress-bar {
    background-color: lighten($brand-primary, 35%);
  }
}
