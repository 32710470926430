@import 'src/stylesheets/Mixins';

.modal-backdrop.show { opacity: .25 }

.modal-container {
  @include screen-sm { top: 0 }
  top: 100px;
  .modal-content {
    border-radius: 0;
    .modal-header { padding: 0 }
    .modal-body { padding: 0 }
    .modal-footer {
      padding: 0;
      & > * { margin: 0 }
    }
  }
}
