@import 'src/stylesheets/Colours';
$caret: url('../../assets/svgs/dropdown-icon.svg');
$border: 1px solid $light-grey;

.header-accordion {
  .accordion-item {
    .accordion-header {
      .accordion-button {
        background-color: white;
        @extend .box-shadow;
        &:focus-visible { outline: none }
        &:after { background-image: none !important }
      }

      .header-accordion-icon {
        img { width: 48px }
      }
      .heading { color: black }
    }
    .accordion-collapse {
      .accordion-body {
        border-bottom: $border;
        border-left: $border;
        border-right: $border;
      }
    }
  }
  &.disabled {
    button.accordion-button  {
      @extend .cursor-not-allowed;
      &:after { transform: rotate(0) }
    }
  }
}

.body-accordion {
  @mixin expanded-styles() {
    background-color: $lighter-grey;
    border: 0;
  }
  .accordion-item {
    .accordion-header {
      .accordion-button {
        background-color: white;
        border: $border;
        border-bottom: none;
        box-shadow: none;
        color: black;
        padding: 15px;
        transition: border 0s;

        &:after {
          background-image: none;
          content: $caret;
        }
        &:focus,
        &:focus-visible {
          box-shadow: none;
          outline: none;
        }
      }
      .accordion-button.collapsed { @include expanded-styles() }
      .heading { color: black }
    }
    .accordion-collapse {
      .accordion-body {
        border-left: $border;
        border-right: $border;
        border-bottom: $border;
      }
    }
    &.disabled {
      .accordion-button {
        @include expanded-styles();
        @extend .cursor-not-allowed;
        &:after { transform: rotate(0) }
      }
    }
  }
}
