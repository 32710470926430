// General Styles
@import 'Buttons';
@import 'Colours';
@import 'Fonts';
@import 'Forms';
@import 'Mixins';
@import 'Selects';
@import 'Utilities';

// Component styles
@import '../components/accordion/Accordion';
@import '../components/bread-crumbs/BreadCrumbs';
@import '../components/charts/Charts';
@import '../components/checkbox/Checkbox';
@import '../components/inputs/Input';
@import '../components/map/Map';
@import '../components/modals/Modals';
@import '../components/navs/Nav';
@import '../components/payment-method/PaymentMethodEdit';
@import '../components/progress-bar/ProgressBar';
@import '../components/radio/Radio';

html, body {
  @extend .font-regular;
  color: black;
}

a {
  color: black;
  text-decoration: none !important;
  &:hover {
    text-decoration: underline !important;
    color: black;
  }
}
