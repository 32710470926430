.tab-menu-charts {
  $height: 150px;

  [id^='chart-'] {
    height: $height !important;
    line-height: 30px!important;
    text-align: left!important;
  }
  .chart-placeholder {height: $height !important}
}

.lone-pie-chart-container {
  [id^='chart-'], .chart-placeholder {height: 348px !important}
}
