input[type='checkbox'].form-check-input {
  $square-dimensions: 1.1em;
  border-radius: 2px;
  border: 2px solid black;
  height: $square-dimensions;
  width: $square-dimensions;

  @extend .cursor-pointer;

  &:checked { background-color: black }
  &:focus { box-shadow: none }
}
