@import 'Colours';
@import 'Fonts';

$caret: url('../assets/svgs/dropdown-icon.svg');

$font-size: 13px;
$input-height: 38px;

@mixin screen-sm {
  @media only screen and (max-width: 576px) { @content }
}

@mixin screen-md {
  @media only screen and (max-width: 768px) { @content }
}

@mixin screen-lg {
  @media only screen and (max-width: 992px) { @content }
}

@mixin min-screen-lg {
  @media only screen and (min-width: 992px) { @content }
}

@mixin screen-xl {
  @media only screen and (max-width: 1200px) { @content }
}

@mixin chrome_specific_styles {
  @media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution:.001dpcm) { @content }
}

@mixin invalid-input-styles { border: 1px solid $invalid-red !important }

@mixin common-input-styles() {
  &.is-invalid { @include invalid-input-styles() }
  &:valid.prevent-valid-validation-ui {
    background-image: none !important;
    border-color: $light-grey !important;
  }

  border: 1px solid $light-grey;
  border-radius: 0;
  box-shadow: none !important;
  font-size: $font-size;
  padding: 9px;

  &.input-group-prepend, &.input-group-prepend.input-group-text {
    background-color: transparent;
    border-radius: 0;
    border-right: none;
    ~ input {
      border-radius: 0;
    }
  }

  &:focus,
  &:focus-visible,
  &:active {
    border: 1px solid $light-grey;
    box-shadow: none;
  }

  &:disabled,
  &[readonly] { background-color: white }
}

@mixin common-placeholder-styles() {
  color: $grey !important;
  font-weight: normal;
  font-size: $font-size;
}

@mixin react-select-menu-items($common-identifier) {
  .#{$common-identifier}__option:hover,
  .#{$common-identifier}__option--is-selected {
    @extend .cursor-pointer;
    background-color: $brand-primary!important;
    color: white;
  }

  .#{$common-identifier}__option--is-focused:not(.#{$common-identifier}__option--is-selected) {
    background-color: white
  }

  @extend .box-shadow-heavy;
  border-radius: 0;
  z-index: 15;
}

@mixin react-select-theme($common-identifier, $icon: $caret) {
  ##{$common-identifier} {
    &.invalid {
      .#{$common-identifier}__control {
        border: 1px solid $invalid-red;
      }
    }
    width: 100%;
    .#{$common-identifier}__control {
      @include common-input-styles();
      @extend .cursor-pointer;
      padding: 0;
      .#{$common-identifier}__value-container {
        height: $input-height;
        .#{$common-identifier}__placeholder {
          @include common-placeholder-styles();
          position: relative;
        }
      }
    }
    &.is-invalid {
      .#{$common-identifier}__control { @include invalid-input-styles() }
    }

    .#{$common-identifier}__control--is-focused,
    .#{$common-identifier}__control--menu-is-open { border: 1px solid $grey }

    .#{$common-identifier}__menu { @include react-select-menu-items($common-identifier) }

    .#{$common-identifier}__input {
      input {
        @include chrome_specific_styles { line-height: 1.5 }
        &:focus { border: 0 !important }
      }
    }

    .#{$common-identifier}__control--is-disabled {
      background-color: white;

      .#{$common-identifier}__indicators {
        display: none;
      }
    }

    .#{$common-identifier}__indicators {
      .#{$common-identifier}__indicator-separator { display: none }

      &:after {
        border-left: solid $light-grey 1px;
        height: 24px;
        content: $icon;
        position: relative;
        right: 10px;
        padding-left: 4px;
      }

      svg { display: none }
    }

    &.readonly {
      .#{$common-identifier}__control {
        @extend .cursor-default;
      }
      .#{$common-identifier}__indicators {
        &:after { display: none }
      }
    }
  }

  .#{$common-identifier}__menu-portal {
    .#{$common-identifier}__menu {
      .#{$common-identifier}__menu-list { @include react-select-menu-items($common-identifier) }
    }
  }
}
