$checked-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2.5' fill='%'/%3e%3c/svg%3e");
$white: white;
$black: black;

input[type='radio'].form-check-input {
  $circle-dimension: 1.25em;
  border: 2px solid $black;
  height: $circle-dimension;
  width: $circle-dimension;

  &:checked {
    background-color: $white;
    background-image: $checked-image;
  }
  &:focus { box-shadow: none }
}

form.was-validated {
  .prevent-valid-validation-ui {
    input[type='radio'].form-check-input {
      &:valid, ~.form-check-label {
        border-color: $black;
        color: $black;

        &:checked {
          background-color: $white;
        }
      }
    }
  }
}
