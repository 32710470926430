@import '../../stylesheets/Colours';

.bread-crumb-container {
  @extend .font-15;
  .breadcrumb {
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;

    .breadcrumb-item {
      text-transform: capitalize;

      [role='button'] { color: black }

      li { color: black }

      a { color: black }

      &:before { color: black }

      &.active {
        padding: 0;
        color: $grey;

        &:before {
          padding: 0 4px;
          color: $grey;
          margin-left: 5px;
          margin-right: 5px;
        }
      }
    }
  }
}
